import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import EmbedVideo from "../EmbedVideo";

const WhosWho = () => {
	const data = useStaticQuery(graphql`{
  content: file(relativePath: {eq: "whos-who.md"}) {
    childMarkdownRemark {
      html
      frontmatter {
        id
        title
      }
    }
  }
  img_quill: file(relativePath: {eq: "about/Boswell Quill.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
  img_whoswho: file(relativePath: {eq: "general/Boswell-Festival-whos-who.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`)

	return (
		<section id="/whos-who" className="p-4 md:p-12">
			<GatsbyImage
				image={data.img_quill.childImageSharp.gatsbyImageData}
				alt={`The Boswell Quill at Auchinleck Churchyard`}
				className={`shadow-md`} />
			<p className={`text-sm pt-2 text-center mb-16`}>Installed in 2020 in Auchinleck Churchyard, The Boswell Quill celebrates the power of the written word.</p>
			<div className="markdown-content mb-12" dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }}></div>
			<GatsbyImage
				image={data.img_whoswho.childImageSharp.gatsbyImageData}
				alt={``}
				className={`shadow-md mb-16 max-w-md mx-auto`} />
		</section>
    );
}

export default WhosWho
