import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import EmbedVideo from '../EmbedVideo'

const About = () => {

	const data = useStaticQuery(graphql`{
		content: file(relativePath: {eq: "about.md"}) {
			childMarkdownRemark {
				html
				frontmatter {
					id
					title
				}
			}
		}
	}`)

	return (
		<section id="/about" className="p-4 md:p-12">
			<div className="markdown-content mb-16" dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }}></div>
			<h3 className={`text-2xl font-heading mb-2`}>Highlights from previous years</h3>
			<EmbedVideo
				src={`https://www.youtube-nocookie.com/embed/fk0kKTizo6g`}
				title={`2024 Marquee Short`}
				containerClassName={`mb-12`}
			/>
			<EmbedVideo
				src={`https://www.youtube-nocookie.com/embed/1JQzfbBnYog`}
				title={`2023 Festival Roundup`}
				containerClassName={`mb-12`}
			/>
			<EmbedVideo
				src={`https://www.youtube-nocookie.com/embed/iDSKG2p_kGk`}
				title={`Boswell Author Short`}
				containerClassName={`mb-12`}
			/>
			<EmbedVideo
				src={`https://www.youtube-nocookie.com/embed/cCOLuk2tamw`}
				title={`Boswell Author Short with titles`}
				containerClassName={`mb-12`}
			/>
			<EmbedVideo
				src={`https://www.youtube-nocookie.com/embed/UqHrEISYT4w`}
				title={`2021 Festival Highlights`}
				containerClassName={`mb-12`}
			/>
		</section>
	)
}

export default About
